body {
  margin: 0;
  padding: 0;
  font-family: 'Futura PT', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8e8e8;
  box-sizing: border-box;
  color: #222;
  font-size: 16px;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 2;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

input {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

/* review above */

#root {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.app__content {
  padding: 40px;
}

.App-intro {
  font-size: large;
}

.menu-button {
  position: relative;
  float: left;
  border: none;
  background-color: #222;
  color: #fff;
  font-size: 24px;
}
